<template>
  <div id="message">
    <el-dialog
      :title="messageFormTitle"
      width="780px"
      :visible.sync="messageDialogVisible"
      :close-on-click-modal="false"
      @close="messageDialogClose"
    >
    
      <el-form
        ref="messageFormRef"
        :model="messageForm"
        :rules="messageFormRules"
        label-position="right"
        label-width="100px"
      >
      <el-row>
        <el-col :span="12">
          <el-form-item label="营地名称" prop="name">
            <el-input v-model="messageForm.name" placeholder="请输入营地名称" clearable />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item  label="营地类型" prop="type">
          <el-select v-model="messageForm.type" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
          </el-form-item>
        </el-col>
          <el-col>
            <el-form-item label="营地地址" prop="adress">
              <el-input v-model="messageForm.adress" placeholder="请输入营地地址" clearable />
            </el-form-item>
          </el-col>
        
          <el-col :span="12" style="height: 62.8px">
            <el-form-item label="审核状态" prop="status">
              <el-radio-group v-model="campForm.status">
                <el-radio :label="1">
                  已审核
                </el-radio>
                <el-radio :label="2">
                  未审核
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
      <el-col :span="12">
            <el-form-item label="联系方式" prop="tel">
            <el-input v-model="messageForm.tel" placeholder="请输入联系方式" clearable />
            </el-form-item>
        </el-col>
         <el-col>
            <el-form-item label="营地介绍" prop="introduce">
              <el-input type="textarea" v-model="messageForm.introduce" placeholder="请输入营地地址" clearable />
            </el-form-item>
          </el-col>
      </el-row>  
      <el-form-item label="场地类型" prop="types">
        <template>
        <el-checkbox-group v-model="checkList">
          <el-checkbox label="草坪"></el-checkbox>
          <el-checkbox label="泥地"></el-checkbox>
          <el-checkbox label="沙地"></el-checkbox>
          <el-checkbox label="岩地"></el-checkbox>
          <el-checkbox label="碎石地"></el-checkbox>
          <el-checkbox label="人工地"></el-checkbox>
        </el-checkbox-group>
        </template>
        </el-form-item>
        <el-form-item label="营地环境" prop="types">
        <template>
        <el-checkbox-group v-model="checkList">
          <el-checkbox label="森林"></el-checkbox>
          <el-checkbox label="山谷"></el-checkbox>
          <el-checkbox label="公园"></el-checkbox>
          <el-checkbox label="近溪流"></el-checkbox>
          <el-checkbox label="近水库"></el-checkbox>
          <el-checkbox label="近湖泊"></el-checkbox>
        </el-checkbox-group>
        </template>
        </el-form-item>
      
        <el-form-item label="可到车辆" prop="types">
        <template>
        <el-checkbox-group v-model="checkList">
          <el-checkbox label="摩托车"></el-checkbox>
          <el-checkbox label="轿车"></el-checkbox>
          <el-checkbox label="SUV"></el-checkbox>
          <el-checkbox label="越野车"></el-checkbox>
          <el-checkbox label="房车"></el-checkbox>
        </el-checkbox-group>
        </template>
        </el-form-item>
        <el-form-item label="配套设施" prop="lable">
        <template>
        <el-checkbox-group v-model="checkList">
          <el-checkbox label="可明火"></el-checkbox>
          <el-checkbox label="可过夜"></el-checkbox>
          <el-checkbox label="可停车"></el-checkbox>
          <el-checkbox label="可淋浴"></el-checkbox>
          <el-checkbox label="可烧烤"></el-checkbox>
          <el-checkbox label="有水源"></el-checkbox>
          <el-checkbox label="有电源"></el-checkbox>
          <el-checkbox label="有信号"></el-checkbox>
          <el-checkbox label="有WIFI"></el-checkbox>
          <el-checkbox label="有餐厅"></el-checkbox>
          <el-checkbox label="有卫生间"></el-checkbox>
          <el-checkbox label="有淋浴间"></el-checkbox>
          <el-checkbox label="有餐厅"></el-checkbox>
          <el-checkbox label="可点外卖"></el-checkbox>
          <el-checkbox label="有充电桩"></el-checkbox>
          <el-checkbox label="有设备租赁"></el-checkbox>
          <el-checkbox label="禁止明火"></el-checkbox>
        </el-checkbox-group>
        </template>
        </el-form-item>
        <el-form-item label="营地特色" prop="types">
        <template>
        <el-checkbox-group v-model="checkList">
          <el-checkbox label="亲子营地"></el-checkbox>
          <el-checkbox label="露天电影"></el-checkbox>
          <el-checkbox label="划船"></el-checkbox>
          <el-checkbox label="飞盘"></el-checkbox>
          <el-checkbox label="徒步"></el-checkbox>
          <el-checkbox label="越野"></el-checkbox>
          <el-checkbox label="骑行"></el-checkbox>
          <el-checkbox label="溯溪"></el-checkbox>
          <el-checkbox label="钓鱼"></el-checkbox>
        </el-checkbox-group>
        </template>
        </el-form-item>
        <el-form-item label="营地主" prop="introduce" >
        <el-upload
          action="https://jsonplaceholder.typicode.com/posts/"
          list-type="picture-card"
          :on-preview="handlePictureCardPreview"
          :on-remove="handleRemove">
          <i class="el-icon-plus"></i>
        </el-upload>
        <el-dialog :visible.sync="dialogVisible">
          <img width="100%" :src="dialogImageUrl" alt="">
        </el-dialog>
        </el-form-item>
         <el-col :span="12" style="height: 62.8px">
        <el-form-item label="是否显示" prop="display">
          <el-radio-group v-model="campForm.display">
            <el-radio :label="1">
              显示
            </el-radio>
            <el-radio :label="2">
              不显示
            </el-radio>
          </el-radio-group>
        </el-form-item>
          </el-col>
      </el-form>
      
      <div slot="footer">
        <el-button @click="messageDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="messageFormSubmit">
          提 交
        </el-button>
      </div>
    </el-dialog>
    <el-row :gutter="20">
      <el-form inline size="small">
        <el-form-item label="搜索营地" prop="name">
          <el-input v-model="messageForm.name" placeholder="请输入营地名" clearable />
        </el-form-item>
          <el-form-item>
            <el-button type="primary" icon="el-icon-search" @click="handleSearch">
              搜索
            </el-button>
          </el-form-item>
          </el-form>

    
    <el-table
      ref="messageTableRef"
      :data="messagePage.list"
      :tree-props="{children: 'children'}"
      row-key="id"
      default-expand-all
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeightNoPage()"
      @row-click="handleRowClick"
    >
    
      <el-table-column prop="id" label="序号" />
      <el-table-column prop="name" label="营地名称" />
      <el-table-column prop="tel" label="联系方式" />
      <el-table-column prop="type" label="营地类型" />
      <el-table-column prop="adress" label="营地地址" /> 
      <el-table-column prop="status" label="审核状态" />
      <el-table-column prop="campOwnerStatus" label="认领状态" />
      <el-table-column label="操作" align="center">
      <template slot-scope="scope">
          <el-button
            v-if="checkPermission(['DEPT_UPDATE'])"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="updateMessage(scope.$index, scope.row)"
          >
            审核
          </el-button>

          <el-button
            v-if="checkPermission(['USER_UPDATE'])"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            v-if="checkPermission(['USER_SELECT'])"
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
        
      </el-table-column>
      
    </el-table>
    <el-pagination
          :total="messagePage.total"
          :current-page="searchForm.pageNum"
          :page-size="searchForm.pageSize"
          :page-sizes="[10, 15, 20]"
          layout="total, sizes, prev, pager, next, jumper"
          background
          @current-change="pageNumChange"
          @size-change="pageSizeChange"
        />
      </el-row>
  </div>
</template>

<script>
import {
  selectMessageList,
  selectDeptInfo,
  selectUserList,
  selectMessageInfo,
  messageExamine,
  updateMessage,
  campExamine,
  cardExamine
} from '@/api/business/message'

export default {
  data () {
    return {
      messageDialogVisible: false,
      checkList: [],
      dialogImageUrl: [],
      dialogVisible: false,
      messageFormTitle: '',
      messageList: [],
      statusList:[],
      messageForm: {
        id:'',
        name:'',
        tel:'',
        type:'',
        adress:'',
        status: '',
        campOwnerStatus:'',
        introduce:'',
        types:''
      },
      campForm: {
        id: '',
        status: ''
      },
    messagePage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1 ,
        pageSize: 10,
        id: '',
        status: '',
        name: ''
      },
      options1: [{
          value: '1',
          label: '通过'
        }, {
          value: '-1',
          label: '未通过'
        }],
      options: [{
          value: '1',
          label: '公共营地'
        }, {
          value: '2',
          label: '收费营地'
        }, {
          value: '3',
          label: '野营地'
        }],
        value: '',
        messageTree: [],
        messageFormRules: {
        name: [{ required: true, message: '名称不能为空', trigger: ['blur', 'change']}],
        type: [{ required: true, message: '名称不能为空', trigger: ['blur', 'change']}],
        tel: [{ required: true, message: '名称不能为空', trigger: ['blur', 'change']}],
      }
    }
  },
  created () {
    selectMessageList(this.searchForm).then(res => {
      this.messagePage = res
    })
    selectMessageTree().then(res => {
      this.messageTree = res
    })
  },
  methods: {
    messageDialogClose () {
      this.$refs.messageFormRef.resetFields()
    },
    handleRemove(file, fileList) {
        console.log(file, fileList);
      },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    campFormSubmit () {
      this.$refs.campFormRef.validate(async valid => {
        console.log("2123"+valid)
        if (valid) {
          console.log("2123"+this.campFormTitle === '营地审核')
          if (this.campFormTitle === '营地审核') {
            console.log("546")
            await campExamine(this.campForm)

          }
          this.campuserPage = await selectCampList(this.searchForm)
          this.campDialogVisible = false
        }
      })
    },
    messageFormSubmit () {
      this.$refs.messageFormRef.validate(async valid => {
        console.log("2123"+valid)
        if (valid) {
          console.log("2123"+this.messageFormTitle === '修改')
          if (this.messageFormTitle === '修改') {
            console.log("546")
            await updateMessage(this.messageForm)

          }
          this.messageuserPage = await selectMessageList(this.searchForm)
          this.messageDialogVisible = false
        }
      })
    },
    handleRowClick (row, column, event) {
      this.$refs.messageTableRef.toggleRowExpansion(row)
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectMessageList(this.searchForm).then(res => {
        this.messagePage = res
      })
    },
    handleUpdate (index, row) {
      this.messageFormTitle = '营地信息修改'
      this.messageDialogVisible = true
      this.selectMessageInfoById(row.id)
    },
    handleInfo (index, row) {
      this.messageFormTitle = '用户详情'
      this.messageDialogVisible = true
      this.selectMessageInfoById(row.id)
    },
    messageExamine (index, row) {
      this.messageFormTitle = '营地审核'
      this.messageDialogVisible = true

      this.selectMessageInfoById(row.id);
      
    },
    selectMessageInfoById (id) {
      selectMessageInfo(id).then(res => {
        this.messageForm.id = res.id
        this.messageForm.name = res.name
        this.messageForm.type = res.type
        this.messageForm.types = res.types
        this.campForm.status = res.status
        this.messageForm.tel = res.tel
        this.messageForm.adress = res.adress
        this.messageForm.introduce = res.introduce
        this.messageForm.campOwnerStatus = res.campOwnerStatus
        this.messageForm.roleIds = res.roleList.map(item => item.id)
      })
    },

    messageTreeNodeClick (data) {
      this.searchForm.status = data.status
      this.searchForm.pageNum = 1
      selectMessageList(this.searchForm).then(res => {
        this.messagePage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectMessageList(this.searchForm).then(res => {
        this.messagePage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectMessageList(this.searchForm).then(res => {
        this.messagePage = res
      })
  }
}
}
</script>

<style>
</style>
