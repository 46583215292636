import axios from '@/common/axios'
import qs from 'qs'

export function messageExamine (data) {
  return axios({
    method: 'put',
    url: '/camp/audit',
    data: qs.stringify(data)
  })
}

export function campExamine (data) {
  return axios({
    method: 'put',
    url: '/camp/audit',
    data: qs.stringify(data)
  })
}

export function selectMessageInfo (id) {
  return axios({
    method: 'get',
    url: '/camp/campInfo/' + id
  })
}

export function selectMessageList (query) {
  return axios({
    method: 'get',
    url: '/camp/list',
    params: query
  })
}
export function updateMessage (data) {
  return axios({
    method: 'put',
    url: '/camp/update',
    data: qs.stringify(data)
  })
}

export function selectCampInfo (id) {
  return axios({
    method: 'get',
    url: '/camp/campInfo/' + id
  })
}
export function cardExamine (data) {
  return axios({
    method: 'put',
    url: '/camp/campOwnerAudit',

    data: qs.stringify(data)
  })
}
export function selectCampList (query) {
  return axios({
    method: 'get',
    url: '/camp/list',
    params: query
  })
}


